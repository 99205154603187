import React from "react";
import { isDateDisabled } from "./isDateDisabled";

export function FormatUTCDate(date) {
  if (!date) return;
  return FormatUSDate(date)
  const date_ = new Date(date);
  const year = date_.getFullYear();
  const month = String(new Date(date).getMonth() + 1).padStart(2, "0");
  const day = String(new Date(date).getDate()).padStart(2, "0");
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}


export function formatDate(dateString) {
  const [year, month, day] = dateString.split('-');
  return `${month}/${day}/${year}`;
}

export function ValidateEmail(email) {
  if (!email) return;
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function ValidatePassword(password) {
  if (!password) return;
  // return /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password);
  return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,20}$/.test(password);
}

export function ValidatePhoneNumber(phone) {
  if (!phone) return;
  return /^(\+\d{1,3}\s?)?(\d{10})$/.test(phone);
}

export function ValidateAmount(amount) {
  if (!amount) return;
  return /^[+-]?\d+(?:\.\d{1,3})?$/.test(amount);
}

export function AdvancedFormatAmount(amount) {
  if (!amount) return;
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
}

export function ErrorMessage(errortype) {
  var message = '';
  if (errortype == "required") {
    message = "This field is required";
  }
  else if (errortype == "email") {
    message = "Please Enter a valid email";
  }
  else if (errortype == "password") {
    message = "Password should be at least 8 characters in length and should include at least one upper case letter, one number, and one special character";
  }
  return message;

}

export const GetNextAvailableDate = (startDate, daysToAdd, datas) => {
  let date = AddDays(new Date(startDate), daysToAdd);
  if (!Array.isArray(datas)) return date;
  while (isDateDisabled(date, datas)) {
    date = AddDays(date, 1);
  }
  return date;
};

export function AddDays(date, days) {
  date.setDate(date.getDate() + days);
  return date;
}

export function FormatUSDate(date) {
  if (!date) return;
  const date_ = new Date(date);
  const year = date_.getFullYear();
  const month = String(new Date(date).getMonth() + 1).padStart(2, "0");
  const day = String(new Date(date).getDate()).padStart(2, "0");
  if (isNaN(month || day || year)) return date
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
}

export function CheckNull(value) {
  if (value == null || typeof (value) == "undefined" || value == NaN || value == "") return ""
  return value;
}

export function FormatMinDate(date) {
  if (!date) return;
  new Date()
  const date_ = new Date(date);
  const year = date_.getFullYear();
  const month = String(new Date(date).getMonth() + 1).padStart(2, "0");
  const day = String(new Date(date).getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}


export function HandleNone(value) {
  return value || '-'
}

export function FormatUTCTime(inputTime) {
  const inputDate = new Date(`2000-01-01T${inputTime.split(' ')[0]}`);
  const localTime = inputDate.toLocaleTimeString(undefined, { timeZone: 'UTC' }); // Assuming UTC time zone
  return localTime || inputTime
}



export function FormatUTCTimeToLocal(UTCTime) {
  if (!UTCTime) return '';
  const utcDate = new Date(UTCTime);
  const localOffset = utcDate.getTimezoneOffset();
  const localDate = new Date(utcDate.getTime() - (localOffset * 60 * 1000));
  return localDate.toLocaleString();
}

export const FormatUTCDateAndTime = (utcDate) => {
  const date = new Date(utcDate);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export function ValidateUrl(url) {
  if (!url) return;
  const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?(\?[^\s]*)?$/i;
  return urlPattern.test(url)
}

export function FormatUSDates(date) {
  if (!date) return;
  const date_ = new Date(date);
  const year = date_.getUTCFullYear();
  const month = String(date_.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date_.getUTCDate()).padStart(2, '0');
  return `${month}/${day}/${year}`;
}

export const FormatDate = (dateString) => {
  const options = { month: 'long', day: 'numeric', year: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};

export const extractPathAfterDomain = (url) => {
  try {
    const parsedUrl = new URL(url);
    const path = parsedUrl.pathname;
    return path.startsWith('/') ? path.slice(1) : path;
  } catch (error) {
    console.error("Invalid URL:", error);
    return '';
  }
};
