import APISources from "../sources"
import { apiCall, getUrl } from "./apicall";

export const getAnalyticsAsync = (data) => apiCall("post", `${APISources().Analytics?.ListURI}`, data)

//Qto, Fte
export const getQTOAnalyticsAsync = (data) => getUrl("analyticsQtoUrl", data);
export const getFTEAnalyticsAsync = (data) => getUrl("analyticsFteUrl", data);

//Filter partner,agent, customer
export const getAnalyticsAgentListAsync = (data) => getUrl("agentUrl", data)
export const getAnalyticsPartnerAsync = (data) => getUrl("partnersUrl", data);
export const getAnalyticsCustomersAsync = (data) => getUrl("customersUrl", data);

