import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import "./assets/scss/style.scss";
import "./assets/scss/responsive.css";
import DefaultLayout from "./pages/defaultLayout";
import Login from "./views/authentication/login";
import OtpVerification from "./views/authentication/otpVerification";
import ForgotPasswordIndex from "./views/authentication/forgotPassword";
import EmailverificationIndex from "./views/authentication/emailVerification";
import ResetPassword from "./views/authentication/resetPassword";
import AppContextProvider from "./contexts";
import Notification from "./components/notifications";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Projects from "./views/projects/index";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const queryClient = new QueryClient()

function App() {
  return (
    <AppContextProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            {/* <Route path="/projects" element={<Projects />}></Route> */}
            <Route exact path="/login" element={<Login />}></Route>
            <Route
              exact
              path="/otpVerification/:email/:customerid"
              element={<OtpVerification />}
            ></Route>
            <Route
              exact
              path="/forgot-password"
              element={<ForgotPasswordIndex />}
            ></Route>
            <Route
              exact
              path="/forgot-password/verify-email/:email/:customerid"
              element={<EmailverificationIndex />}
            ></Route>
            <Route
              exact
              path="/forgot-password/reset-password/:email"
              element={<ResetPassword />}
            ></Route>
            <Route path="*" name="Dashboard" element={<DefaultLayout />} />
          </Routes>
        </Router>

        <Notification />
        <ToastContainer
          stacked
          style={{ margin: "20px" }}
          autoClose={1000}
          position="top-right"
        />
      </QueryClientProvider>
    </AppContextProvider>
  );
}

export default App;
