//LamdaApiUrls.js

//const mode = "Stage"
const mode = "Prod"

// Dashboard
const subscriptionApi = `https://z30jxhv0md.execute-api.us-east-1.amazonaws.com/${mode}/`;
const partnersApi = `https://r2y8hb6um2.execute-api.us-east-1.amazonaws.com/${mode}/`;
const customersApi = `https://dovousa67d.execute-api.us-east-1.amazonaws.com/${mode}/`;
const countDetailsApi = `https://onytoq1002.execute-api.us-east-1.amazonaws.com/${mode}/`;
const npsDashboardApi = `https://csrnisyew7.execute-api.us-east-1.amazonaws.com/${mode}/`;
const top5CustomersApi = `https://g97dal53ra.execute-api.us-east-1.amazonaws.com/${mode}/`;
const top5BillingsApi = `https://2zdnfzqdi3.execute-api.us-east-1.amazonaws.com/${mode}/`;
const latestReviewsApi = `https://0qeocf5r3c.execute-api.us-east-1.amazonaws.com/${mode}/`;
//const dashboardInvoiceApi = `https://g6cemw5ot4.execute-api.us-east-1.amazonaws.com/Stage/`;
const dashboardInvoiceApi = `https://tixbvvnc7h.execute-api.us-east-1.amazonaws.com/${mode}/`;
const invoiceGraphApi = `https://pil65r4k10.execute-api.us-east-1.amazonaws.com/${mode}/`;
//const invoiceGraphApi = `https://8lrsuzvgkg.execute-api.us-east-1.amazonaws.com/Stage/`;
const projectStatusApi = `https://6w01o0jpo3.execute-api.us-east-1.amazonaws.com/${mode}/`;
const userServiceApi = `https://ot0ye9ekt0.execute-api.us-east-1.amazonaws.com/${mode}/`;
const projectDashboard = `https://rkl0y2g3a4.execute-api.us-east-1.amazonaws.com/${mode}/`;

//Invoice Listing
const invoiceApi = `https://bv81sewwdk.execute-api.us-east-1.amazonaws.com/${mode}/`;
const invoiceViewApi = `https://vi3p8iui42.execute-api.us-east-1.amazonaws.com/${mode}/`;
const monthYearApi = `https://4rrm8u1oka.execute-api.us-east-1.amazonaws.com/${mode}/`;

//const monthYearApi = "https://d6xttmnyy0.execute-api.us-east-1.amazonaws.com/Stage/"
const downloadInvoiceApi = `https://9qhs9xvmy4.execute-api.us-east-1.amazonaws.com/${mode}/`;
const sendInvoiceApi = `https://ptqjjmlq20.execute-api.us-east-1.amazonaws.com/${mode}/`;

//Analytics
const analyticsApi = "https://kea820r6si.execute-api.us-east-1.amazonaws.com/Prod/";
const analyticsQtoApi = `https://m6puff9a2l.execute-api.us-east-1.amazonaws.com/${mode}/`;
const analyticsFteApi = `https://y5362gibqk.execute-api.us-east-1.amazonaws.com/${mode}/`;

//const analyticsApi = `https://s5zt2os4u0.execute-api.us-east-1.amazonaws.com/Stage/`;

//analyticsStage ? month = 5 & year=2024 & month_filter=2 & service=fte & partner=& customer=

//projects
const projectListApi = `https://qyyjxg4qfg.execute-api.us-east-1.amazonaws.com/${mode}/`;
//const projectStageListApi = `https://365i64w902.execute-api.us-east-1.amazonaws.com/Stage/`;

//Agent
const AgentListApi = `https://npnvx2570d.execute-api.us-east-1.amazonaws.com/${mode}/`;

//Storage
const storageApi = `https://trwl7jnk62.execute-api.us-east-1.amazonaws.com/${mode}/`;

//Agent 
export const agentUrl = `${AgentListApi}agentList`;

// Dashboard
export const subscriptionChartUrl = `${subscriptionApi}subscriptionChart`;
export const partnersUrl = `${partnersApi}partners`;
export const customersUrl = `${customersApi}customers`;

export const countDetailsUrl = `${countDetailsApi}countDetails`;
export const npsDashboardUrl = `${npsDashboardApi}npsDashboard`;

export const top5CustomersUrl = `${top5CustomersApi}top5Customers`;
export const latestReviewsUrl = `${latestReviewsApi}latestReviews`;
export const top5BillingsUrl = `${top5BillingsApi}top5Billings`;

//export const dashboardInvoiceUrl = `${dashboardInvoiceApi}dashboardInvoiceStage`;
export const dashboardInvoiceUrl = `${dashboardInvoiceApi}dashboardInvoice`;
export const invoiceGraphUrl = `${invoiceGraphApi}invoiceGraph`;
//export const invoiceGraphUrl = `${invoiceGraphApi}invoiceGraphStage`;
export const projectStatusUrl = `${projectStatusApi}projectStatus`;
export const userServiceUrl = `${userServiceApi}userService`;

//Invoice Listing
export const invoiceUrl = `${invoiceApi}invoiceList`;
export const invoiceViewUrl = `${invoiceViewApi}invoiceView`;
export const downloadInvoiceUrl = `${downloadInvoiceApi}invoicedownload`;
export const sendInvoiceUrl = `${sendInvoiceApi}sendinvoice`;
export const customerUrl = `${customersApi}customers`;
export const monthYearUrl = `${monthYearApi}monthYear`;
//export const monthYearUrl = `${monthYearApi}dropdown`;

//Analytics
export const analyticsUrl = `${analyticsApi}analytics`;
//export const analyticsUrl = `${analyticsApi}analyticsStage`;
export const analyticsQtoUrl = `${analyticsQtoApi}analyticsQto`;
export const analyticsFteUrl = `${analyticsFteApi}analyticsFte`;

//Projects
export const projectListUrl = `${projectListApi}projectList`;
//export const projectListUrl = `${projectStageListApi}projectlistStage`;
export const projectDashboardUrl = `${projectDashboard}projectsDashboard`;

//Storage
export const storageUrl = `${storageApi}fileDetails`
